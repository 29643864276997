.growth{
  &-wrap{
    padding-top: 90px;
    overflow: hidden;
    .component-title{
      text-align: center;
      margin-bottom: 20px;
    }
    .component-subtitle{
      text-align: center;
      max-width: 770px;
      margin: auto;
      @media all and  (min-width: @min-md){
        margin-bottom: 125px;
      }
    }
    .slider__navigation{
      @media all and  (max-width: (@max-sm + 1px)){
        margin-bottom: 30px;
      }
    }
  }
  &-item{
    position: relative;
    overflow: hidden;
    flex: 0 0 26%;
    .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));
    background-color: @wc;
    border-radius: 4px 4px 0 0;
    padding: 25px 10px 155px 20px;
    color: @gc3;
    @media all and  (max-width: (@max-sm + 1px)){
      margin: 0 10px;
    }
    @media all and  (min-width: @min-sm) and (max-width: (@max-sm + 1px)){
      flex: 1 0 44%;
      margin-bottom: 20px;
    }
    &:first-child{
      .growth-item-title{
        color: #278759;
      }
    }
    &:nth-child(2){
      .growth-item-title{
        color: #3a507d;
      }
    }
    &:nth-child(3){
      .growth-item-title{
        color: #eead49;
      }
    }
    &:last-child{
      .growth-item-title{
        color: @rc2;
      }
    }
    &-title{
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 25px;
    }
    &-list{
      &-info{
        line-height: 24px;
        font-size:16px;
          &:before{
            content: '\b7\a0';
            color: @rc2;
           font-size: 1.3em;
          }
        span{
          font-weight: 700;
          font-size: 20px;
        }
      }
      &:after{
        content: '';
        display: block;
        width: 128px;
        height: 1px;
        background-color: #c8d5d9;
        margin-top: 15px;
      }
    }

    &:before{
      content: '';
      display: block;
      .border-radius(100%);
      background-color: #eef1f2;
      height: 210px;
      width: 210px;
      position: absolute;
      bottom: -50px;
      right: -45px;
    }
    &:after{
      content: '';
      display: block;
      width: 100px;
      height: 220px;
      position: absolute;
      bottom: 0;
      right: 20px;
      background-repeat: no-repeat;
      background-position: 100% 100%;
    }
    &-first:after{
      background-image: url(images/growth/growth-1.png);
    }
    &-second:after{
      .growth_2_animate;
      background-image: url(images/growth/growth-2.png);
    }
    &-third:after{
      .growth_3_animate;
      background-image: url(images/growth/growth-3.png);
    }
    &-fourth:after{
      .growth_4_animate;
      background-image: url(images/growth/growth-4.png);
    }
  }
  &-gradation-list{
    @media all and  (max-width: (@max-sm + 1px)){
      margin: 20px auto;
    }
    @media all and  (max-width: (@max-xs + 1px)){
      max-width: 280px;
    }
    @media all and  (min-width: @min-sm ){
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}
