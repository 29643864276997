.footer{
  &-wrap{
    background-color: @wc;
    font-size: 14px;
    padding: 80px 0;
    @media all and  (max-width: @max-md){
      text-align: center;
      padding: 20px 0;
    }
    .flex-first,
    .flex-last{
      flex: 1 0 33%;
      @media all and  (max-width: @max-sm){
        margin-bottom: 10px;
      }
    }
    .flex-last{
      @media all and  (min-width: @min-md){
        text-align: right;
      }
    }

  }

  &-logo{
    margin-bottom: 30px;
    .logo-link {
      display: inline-block;
      svg {
        max-width: 100px;
        max-height: 40px;
      }
    }
    @media all and  (max-width: @max-md){
      display:flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 10px;
    }

  }
  &-adress {
    line-height: 19px;
  }

  &-adress-first{
    margin-top: 13px;
  }

  &-phone{
    margin-bottom: 5px;
    svg {
      max-width: 190px;
      max-height: 20px;
    }
    @media all and  (max-width: @max-xs){
      text-align: center;
      margin-bottom: 20px;
    }
  }
  &-wrap .fb, &-wrap .rabit{
    margin-left:120px;
    max-width: 100px;
    display: inline-block;
    @media all and  (max-width: @max-md){
      
      margin: 30px auto 0;
    }
}
  &-wrap .rabit{
    margin-left:40px;
    img{
      height: 45px;
    }

     @media all and  (max-width: @max-md){
      
      margin: 20px auto 0;
    }
  }
  &-copy{
    font-family: "Roboto Condensed";
    color:#636a76;
    margin-top: 17px;
    font-size: 10px;
    line-height: 12px;
    @media all and  (max-width: @max-md) {
      margin-top: 10px;
    }
  }
  &-links{
    margin-top: 45px;
    @media all and  (max-width: @max-md){
      margin-top: 15px;
    }
    div{
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border-style: none;
      box-shadow: 2px 3px 11px 0px rgba(0, 0, 0, 0.3);
    }
    ul{
      display: flex;
      justify-content: flex-end;
      margin: 0;
      @media all and  (max-width: @max-md){
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    li{
      list-style: none;
      padding: 0 5px;
      @media all and  (max-width: @max-md){
        padding: 10px 5px;
        flex: 1 0 16%;
      }
    }
  }
}
.support-link{ 
  padding-bottom: 5px;
}

#menu-footer{

    li{
    padding: 10px;
  }
  a{
    color: @gc2;
    display: inline-block;
  }
}

.footer-info{
  font-size: 10px;
  padding: 40px 20px 0;
  text-align: center;
  color: #9499a5;
}


.tos-links{
  display: inline-block;
  margin-left: 65px;
  ul{
    display: inline-block;
    margin: 0;
    vertical-align: super;
  }
  li {
    list-style: none;
    display: inline-block;
    padding: 0 10px;
  }
}