/* ----------------------------------------------------------------------
   RESET
   ---------------------------------------------------------------------- */

:focus {
  outline: none;
}

html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  min-width: 300px;
  max-width:100%;
  width:100%;
  background-color: #fff;
  font-family: @roboto;
  font-size: 16px;
  font-weight: 400;
  color: @gc3;
  @media all and  (max-width: @max-lg){
    font-size: 14px;
  }
}

h1, h2, h3, .h1, .h2, .h3,
h4, h5, h6, .h4, .h5, .h6 {
  font-family: @playfair;
  font-weight: 700;
  color: @bc;
}

ul{
  padding: 0;
  list-style-position: inside;
}

a {
  outline: none;
  color: @gc3;
  text-decoration: none;
  &:hover {
    color: @rc2;
  }
  &:active{
    color: @rc3;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.hidden{display:none}

/* ----------------------------------------------------------------------
   LAYOUT
   ---------------------------------------------------------------------- */
article h1,
article h2,
article h3{
  color: @gc3;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.menu{
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  li{
    display: inline-block;
    list-style: none;
    padding: 0 15px;
    &:last-child{
      padding-right: 0;
    }
    &.current-menu-item{
      a{
        &::after {
          background: @gc2;
          width: 100%;
        }
      }
    }

    @media screen and (min-width: @min-sm ) and (max-width: @min-lg ) {
      padding: 0 10px;
    }
    @media screen and (min-width: @min-sm) and (max-width: 850px) {
      padding: 0 3px;
    }
  }
  a {
    outline: none;
    color: @gc2;
    font-weight: 400;
    text-decoration: none;
    display: block;
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      transition: width .3s;
    }
    &:hover {
      @media screen and (min-width: @min-sm ) {
        &::after {
          background: @gc2;
          width: 100%;
        }
      }
    }
    &:active{
      color: @gc3;
    }
  }
}

#menu-header-menu{
  & li.current-menu-item{
    a{
      &::after {
        background: @rc3;
        width: 100%;
      }
    }
  }
  a {
    color: @rc2;
    //padding-bottom: 5px;
    &:hover {
      &::after {
        background: @rc2;
        width: 100%;
      }
    }
    &:active{
      color: @rc3;
    }
  }
}

.component{
  &-wrap{
    position: relative;
    max-width: 1160px;
    margin: auto;
    z-index: 1;
    @media all and (max-width: @max-lg){
      padding: 0 15px;
    }
    @media all and  (max-width: @max-sm){
      display: block;
    }
  }

  &-item{
    flex: 1 0 50%;

    &_blocks{
      @media screen and (max-width: @max-md){
        width: 100%;
      }
    }
  }
  &-title-white{
    text-align: left;
    font-size: 48px;
    font-family: @playfair;
    font-weight: 700;
    color: @wc;
    margin-bottom: 25px;
    position: relative;
    @media all and  (max-width: @max-lg){
      br{
        display: none;
      }
    }
    @media all and  (max-width: @max-md){
      font-size: 36px;
      //padding: 0 5px;
      //line-height: 24px;
    }
  }
  &-subtitle-white {
    text-align: left;
    font-size: 16px;
    font-family: @roboto;
    font-weight: 400;
    color: @wc;
    line-height: 24px;
    //max-width: 1000px;
    //margin: auto;
    @media all and  (max-width: @max-lg){
      br{
        display: none;
      }
    }
    @media all and  (max-width: @max-md){
      font-size: 14px;
      padding: 0 5px;
    }
  }
  &-title{
    text-align: left;
    font-size: 48px;
    font-family: @playfair;
    font-weight: 700;
    color: @bc;
    margin-bottom: 25px;
    position: relative;
    @media all and  (max-width: @max-lg){
      br{
        display: none;
      }
    }
    @media all and  (max-width: @max-md){
      font-size: 36px;
      //padding: 0 5px;
      //line-height: 19px;
    }
  }
  &-subtitle {
    text-align: left;
    font-size: 16px;
    font-family: @roboto;
    font-weight: 400;
    color: @gc3;
    line-height: 24px;
    //max-width: 1000px;
    //margin: auto;
    @media all and  (max-width: @max-lg){
      br{
        display: none;
      }
    }
    @media all and  (max-width: @max-md){
      font-size: 14px;
      padding: 0 5px;
    }
  }
}

input{
  color: @bc;
}

.pagingInfo{
  @media all and  (min-width: @min-md){
    display: none;
  }
}

.slider {
  .slider__navigation_light {
    @media all and  (max-width: 768px) {
      .slick-arrow {
        &:active,
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .slider__navigation_dark {
    @media all and  (max-width: 768px) {
      .slick-arrow {
        &:active,
        &:hover {
          color: #8a1328;
        }
      }
    }
  }
}
.slider__navigation_vert{
  .pagingInfo{
    @media all and  (min-width: 768px){
      display: none;
    }
  }
}
.seo-wrap{
  p,
  ul>li,
  ol>li{
    line-height: 24px;
    padding: 5px 0;
  }
}


.light-bg{
  background-color: @wc;
  .header-wrap{
    padding: 15px 0 !important;
  }
}

.fixed{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 40;
}


.menu-is-open #mia-chat-button{
  z-index: 9!important;
}

#mia-chat-btn-open svg {
  margin-top: 11px!important;
}