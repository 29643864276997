@font-face {
  font-family: 'Kozuka Gothic Pro Regular';
  src: url(webfonts/KozGoPro-Regular.otf);
}

@font-face {
  font-family: 'Kozuka Gothic Pro';
  src: url(webfonts/KozGoPro-Light.otf);
}

.unsere-mission {



  .img-slider {

    color: #c1d6ff;
    margin-top: 100px;

    background: url(images/unsere-mission/unsere-mission-shuttle.jpg) center top no-repeat;

    &__box {
      max-width: 1160px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      height: 434px;
      padding-left: 15px;
      padding-right: 15px;
    }
    &__content {

    }
    &__title {
      margin: 0;
      font-size: 32px;
      font-family: 'Kozuka Gothic Pro Regular';
      line-height: 2.0em;
      font-weight: normal;

      color: #c1d6ff;
      background: url(images/unsere-mission/work-icon.png) no-repeat left;
      padding-left: 50px;
    }
    &__text {
      max-width: 376px;
    }

    &__btn {
      padding: 12px 0;
      border-radius: 4px;
    }

  }

  .unsere-mission-content{
    h2.policy {
      background: url(images/unsere-mission/star.png) no-repeat left;
      padding-left: 40px;
      line-height: 1.5em ;
      margin-top: 50px;
      font-family: 'Kozuka Gothic Pro Regular';
      color: #464646;
    }
    p{
      font-weight: 400;
      color: #636a76;
      font-size: 14px;
      line-height: 1.5em;
      text-align: left;

    }
    &__imgbox{
      display: flex;

      img{
        padding-top: 20px;
        padding-right: 40px;
      }

      h4{
        margin: 0;
        line-height: 2.2em;
        font-size: 18px;
        font-style: normal;
        font-family: 'Kozuka Gothic Pro';
        color: #464646;
        font-weight: normal;
      }
    }

    h3 {
      color: #434343;
      padding: 20px 0 10px 0;
      margin: 0;
      font-size: 18px;
      font-style: normal;
      font-family: 'Kozuka Gothic Pro';
      color: #464646;
      font-weight: normal;
    }
  }

}