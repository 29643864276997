.privacyCookie{
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  text-align: center;
  font-size: 12px;
  font-family: Arial,sans-serif;
  padding: 10px 0;
  background: #372370;
  box-sizing: border-box;
  @media screen and (max-width: 1148px){
    padding: 10px 15px;
  }
  .inline{
    display: inline;
    color: #fff;
    line-height: 1.42857143;
  }
  a{
    color: #fff;
    text-decoration: underline;
  }
  button{
    cursor: pointer;
    text-transform: capitalize;
    font-size: 13px;
    color: #372370;
    background: #ffffff;
    border: 0;
    border-radius: 4px;
    padding: 4px 15px;
    display: inline-block;
    margin-left: 15px;
    line-height: normal;
    @media screen and (max-width: 1148px){
      display: block;
      min-width: 150px;
      margin: 10px auto 0 auto;
    }
  }
}


//if is page privacy-policy or terms-and-conditions
.page-id-1045,
.page-id-1047{
  .page__content {
    @media all and  (max-width: 991px) {
      padding: 70px 0 40px;
    }
  }
}
.txtBaner{

  width: 100%;

  text-align: center;
  font-size: 14px;
  font-family: Arial,sans-serif;
  padding: 10px 0;
  background: #372370;
  box-sizing: border-box;
  color: #fff;
  @media screen and (max-width: 1148px){
    padding: 10px 15px;
  }
}
