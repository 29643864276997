.header {
  transition: background-color 0.3s;

  &-wrap {
    padding: 12px 0;
    position: relative;
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: space-between;
    @media all and  (max-width: @max-lg) {
      flex-direction: row;
      padding: 15px;
    }
    @media all and  (max-width: (@max-sm + 1px)) {
      //display: block;
    }
  }

  .btn-item {
    text-align: right;
    @media all and  (min-width: @min-md) and (max-width: @max-md) {
      display: none;
    }
    @media all and  (max-width: @max-sm) {
      a {
        display: block;
        max-width: 180px;
        margin: auto;
        margin-top: 20px;
      }
    }
  }
  .logo-link {
    position: relative;
    display: inline-block;
    z-index: 1000;
    @media all and  (min-width: (@min-md - 1px)) {

    }
    @media all and  (max-width: (@max-sm - 1px)) {
      display: inline-block;
    }
  }
  .phone {
    max-width: 240px;
    max-height: 21px;
  }

  &-wrap-menu {
    text-align: right;
    padding: 25px 0;
    @media all and  (max-width: @max-lg) {
      padding: 25px 15px;
    }
    @media all and  (max-width: @max-sm) {
      display: none;
    }
  }
  .flex-first {
    @media all and  (min-width: @min-lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.scrollr {
    background-color: rgba(255, 255, 255, .9);

    .header-wrap {
      padding: 12px 0;
      .anime;

      @media all and  (max-width: @max-lg) {
        padding: 15px;
      }
      @media all and  (max-width: (@min-sm - 1)) {
        padding: 15px 15px 0;

        .header-item_logo {
          border-bottom: none;
        }
      }
    }

    .header-wrap-menu {
      display: none;
    }
  }

  &.mmenu-open {
    .linear-gradient(90deg, rgba(255, 255, 255, 0.97), rgba(227, 231, 232, 0.97));

    //background-color: rgba(255,255,255,.9);
  }

  &-item {
    &.header-item_logo {
      flex: 0 0 105px;
      padding: 3px 25px 6px 0;
    }

    &.header-item_phone {
      flex: 1 0 235px;
    }

    &.header-item_menu {
      flex: 1 0 auto;
      transition: opacity 0.4s;
    }

    @media all and (max-width: (@min-sm - 1) ) {
      &.header-item_logo {
        text-align: center;
      }
    }

    @media all and (min-width: @min-sm ) {
      &.header-item_phone {
        display: none;
      }

      &.header-item_menu {
        display: inline-block;
        margin-right: 20px;
      }
    }

    @media all and (min-width: @min-md ) {
      &.header-item_logo {
        margin-right: 35px;
        border-right: 1px solid #d7d7d7;
      }

      &.header-item_menu {
        display: block;
        margin-right: 30px;
      }
    }

    @media all and (min-width: @min-lg ) {
      &.header-item_phone {
        display: block;
      }

      &.header-item_menu {
        display: block;
        margin-right: 0;
      }
    }
  }
}

.header_main_page {

  @media all and (min-width: @min-lg ) {
    .header-item_menu {
      order: 3;
      flex: 1 0 100%;
      padding-top: 30px;
      justify-content: flex-end;
      text-align: right;
    }

    &.scrollr {
      .header-item_menu {
        order: 0;
        flex: 1 0 auto;
        padding-top: 0;
        animation: header-jump 1s;
        margin-right: 40px;
      }
    }

    &.is-init {
      .header-item_menu {
        animation: header-jump2 1s;
      }
    }
  }
}

.main-nav {
  a {
    color: #b20c2a;
    font-family: @roboto, sans-serif;
    font-size: 16px;
  }
}

/*HEADER: SLICKNAV MENU*/
.mobile-nav {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  height: 55px;

  @media all and  (min-width: (@min-sm)) {
    display: none;
  }
}

.header-items-wrap {
  flex: 1 0 auto;
  align-self: center;

  @media all and (max-width: (@min-sm - 1) ) {
    display: none;
  }
}

.header-items {

  @media screen and (max-width: (@min-sm - 1)) {
    overflow: auto;
    height: 100%;
    min-height: 100%;
    padding-bottom: 100px;
  }

  @media screen and (min-width: @min-sm) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row wrap;
    width: 100%;
    height: 38px;
  }
}

.slicknav {
  &_menu {
    display: inline-block;
    float: right;

    > a {
      position: relative;
      display: inline-block;
      height: 30px;
      padding: 5px 13px;
    }

    .menu-item {
      padding: 0;
    }
  }

  &_open .slicknav_icon-bar {
    &:first-child {
      transform: rotate(45deg);
      position: relative;
      top: 10px;
    }
    &:nth-child(2) {
      display: none;
    }
    &:last-child {
      transform: rotate(-45deg);
      position: relative;
      top: 3px;
    }
  }
  &_btn {
    font: 700 20px/20px @roboto, sans-serif;
    z-index: 10000000;
  }
  &_menutxt, &_icon {
    float: left;
  }
  &_menutxt {
    display: none;
  }
  &_icon-bar {
    display: block;
    font: 700 16px/20px @roboto, sans-serif;
    width: 30px;
    height: 2px;
    margin: 5px 0;
    background: @gc3;
  }
  &_nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    margin: 0;
    padding: 15px 0 30px;
    text-transform: uppercase;
    text-align: center;
    overflow: hidden;
    z-index: 10;
    background-color: rgba(255, 255, 255, .95);

    ul {
      margin: 0
    }

    li {
      display: block;
    }

    li a {
      display: block;
      padding: 10px;
      color: @rc2;
      font-size: 20px;
    }

    .phone-link {
      position: relative;
      display: block;
      margin: 80px 0 15px;

      @media only screen and (max-width: (@max-sm) ) {
        display: inline-block;
        background: url("./images/phone-img-new.png") no-repeat;
        width: 210px;
        height: 21px;

        svg {
          display: none;
        }
      }
    }
  }
}

.mobile-btn {
  display: none;
  position: absolute;
  top: 20px;
  right: 5px;
  width: 30px;
  height: 20px;

  @media all and (max-width: (@min-sm - 1px) ) {
    display: inline-block;
  }
}

.header {
  @media all and (max-width: (@min-sm - 1px) ) {

    .header-item_logo {
      position: relative;
      z-index: 11;
      padding: 3px 0 15px 0;
      border-bottom: 1px solid #d7d7d7;
    }

    .header-item_phone {
      padding: 15px 0;
      text-align: center;
    }

    .header-item {
      &.header-item_menu {
        margin-right: 0;
      }
    }

    .main-nav {
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid #d7d7d7;
    }

    .menu-item {
      margin-bottom: 25px;
    }
  }
}

body {

  &.menu-is-open {
    overflow: hidden;

    .header-item_logo {
      background-color: rgba(255, 255, 255, .95);
    }
  }
}

@keyframes header-jump {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes header-jump2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}
