.payments{
  &-wrap{
    padding:35px 0;
    @media all and  (max-width: @max-md){
      display: none;
    }
  }
  &-item{
    img{
      filter: grayscale(100%);
      &:hover{
        .anime;
        filter: none;
      }
    }
  }
}