.blog {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #434343;
}

.top-line {
  width: 100%;
  background: #eeeeee;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  margin-top: 150px;

  &_default {
    margin: 0;
  }

  &__box {
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (max-width: 1199px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (max-width: 425px) {
      flex-direction: column;
      align-items: normal;

    }
  }

  h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 24px;
    color: #464646;
    margin: 0;
  }

  .top-links {
    ul {
      margin: 0 0 0 100px;
      @media (max-width: 425px) {
        margin: 0;
      }
      list-style-type: none;
      display: flex;
      li {
        outline: none;
        border: none;
        margin-right: 5px;
        padding-right: 5px;
        line-height: 0.8em;
        border-right: 1px solid #aaa;

        a {
          font-size: 12px;
          text-decoration: none;
          color: #aaaaaa;

          &:hover {
            text-decoration: underline;
            color: #a58b8c;
          }
        }
      }
    }
  }
}

.blog-container {
  border-bottom: 1px solid #e4e4e4;
  padding-top: 15px;
  padding-bottom: 15px;

  &_default {
    border: none;
  }

  &__post {
    position: relative;
  }
  &__date {
    position: absolute;
    top: 0;
    right: 0;
    color: #aaa;
    font-size: 11px;
  }
  .blog-content {
    padding-top: 15px;
    display: flex;
    @media (max-width: 425px) {
      flex-direction: column;
      align-items: center;
    }

    &__img {
      flex: none;
      margin-right: 15px;
      border: 1px solid #e4e4e4;
    }

    &__text {
      h4 a {
        text-decoration: none;
        color: #464646;
        font-size: 18px;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
}

.blog-post-content {
  font-family: 'Open Sans', sans-serif;
  .blog-img {
    float: left;
    overflow: hidden;
    margin: 0 15px 5px 0;
    border: 1px solid #e4e4e4;
    width: 150px;
    height: 150px;
  }
  h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
    margin-bottom: 12px;
    font-weight: 400;
  }
  h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
    line-height: 19px;
    text-align: justify;
    margin-bottom: 4px;
    color: #464646;
  }
  ul li {
    margin-left: 40px;
    font-size: 14px;
  }
  a {
    color: #930;
    text-decoration: underline;
  }
}

.pagination-for-blog {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-top: 10px;
  @media (max-width: 768px) {
    text-align: center;
  }

}

.blog-footer {
  margin-top: 15px;
  .soc-services {

    margin-top: 15px;
    display: flex;
    @media (max-width: 768px) {
      justify-content: center;
    }
    &__tw {
      margin-right: 2px;
    }
  }
}
