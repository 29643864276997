#typeform-full {
  margin-top: 70px;
width: 100%;
min-height: 600px;
}
.iframe-checkbox {
//background:rgba(146, 161, 169, 0.2);
//padding: 30px 10px;
display: flex;
flex-direction: column;
align-items: left;
  a{
    color: #000;
  }
}
.checkbox{
  text-align: left;
  //width:600px;
  //@media all and (max-width:768px){
  //  width: 90%;
  //}
  //@media all and (max-width:425px){
  //  text-align: left;
  //  padding-bottom: 5px;
  //  width: 100%;
  //}
  padding: 12px 0;
}
.form-referense {
  text-decoration: underline;
}
/* styles for terms page*/
.terms {
    margin-top:70px;
background: rgba(185, 198, 204, 0.2);
padding-bottom: 15px;
}
.terms-text {
  margin: 0 auto;
  width: 800px;
  @media all and (max-width: 1024px){
    width: 85%;
  }
  @media all and (max-width: 768px){
    width: 90%;
  }
  @media all and (max-width: 425px){
    width:95%;
  }
}
/* Styles for privacy-policy page */
.privacy {
  padding: 15px 0;
  margin-top:70px;
  background: rgba(185, 198, 204, 0.2);
}
.privacy-text {
  margin: 0 auto;
  width: 800px;
  @media all and (max-width: 1024px){
    width: 85%;
  }
  @media all and (max-width: 768px){
    width: 75%;
  }
  @media all and (max-width: 425px){
    width:95%;
  }
}
.privacy-center {
  text-align: center;
}
.privacy-font-lg{
  font-size:22px;
  color:grey;
  opacity: 0.8;
}
.privacy-right {
  text-align: right;
}


.is-hidden--off-flow {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: -10;   /* *1* */
  visibility: hidden;   /* *1* */
}

.is-shown--off-flow {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  visibility: visible;
}

.l-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0 auto;
  z-index: 55;
  text-align: center;
}

.l-modal__shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  background: #4d4d4d;
  opacity: 0.92;
  z-index: 50;
  cursor: pointer;
}

.l-modal__body {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.c-popup {
  display: inline-block;
  text-align: center;
  background-color: #f9f9f9;
  max-width: 655px;
  //width: 90%;
  padding: 30px 35px;
  z-index: 55;
  font-family: @roboto;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

.c-popup-link{
  text-align: right;
  color: #676767;
  text-decoration: underline;
}
.c-popup-title{
  padding: 20px 0;
  font-weight: 700;
  font-size: 18px;
}

.check_box {
  display:none;
}

.check_box + label{
  background:url('images/nchecked.jpg') no-repeat;
  //height: 16px;
  //width: 16px;
  display:inline-block;
  padding: 0 0 0 45px;
}

.checkbox:first-child .check_box + label {
  line-height: 35px;
}

  .check_box:checked + label{
  background:url('images/checked.jpg') no-repeat;
  //height: 16px;
  //width: 16px;
  display:inline-block;
  padding: 0 0 0 45px;
}

  .errorCheck{
    color: #f00;
    a{
      color: #f00;
    }
  }