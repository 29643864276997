.how-pay{
  &-wrap{
    .linear-gradient;
    padding: 50px 0;
  }
  &-list{
    @media all and  (min-width: (@min-sm - 1px)) and (max-width: @max-md){
      flex-wrap: wrap;
      display: flex;
    }
    @media all and  (min-width: @min-md){
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: right;
    }
    @media all and  (max-width: @max-xs){
      max-width: 230px;
      margin: 20px auto;
    }
  }
  &-item {
    &:not(:last-child) {
      text-align: center;
      background-color: @wc;
      .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));
      .border-radius(4px);
      @media all and  (min-width: (@min-sm - 1px)) and (max-width: @max-md) {
        margin: 10px;
        flex: 0 0 40%;
      }
      @media all and  (min-width: @min-lg) {
        flex: 0 0 43%;
        margin: 15px 10px;
      }
      @media all and  (min-width: @min-md) {
        flex: 0 0 43%;
        margin: 15px 7px;
      }
      @media all and  (max-width: @max-sm) {
        margin: 20px 0;
      }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    padding: 0 10px;
    @media all and  (max-width: @max-sm) {
      &:last-child{
        height: auto;
      }
    }
    @media all and  (min-width: @min-sm) {
      width: 263px;
    }
  }
}