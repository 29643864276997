.impressum{
  padding: 70px 0 30px;
  background: linear-gradient(117.02deg, #DEE3E4 0.37%, #F9F9FA 98.79%);

  &__wrapper{
    background: #FFFFFF;
    box-shadow: 0 0 26px #EBF1F2;
    display: flex;
    padding: 70px 70px 70px 70px;
    justify-content: space-between;
    margin: 50px auto;
    max-width: 1030px;
  }

  &__info{
    max-width: 530px;
  }

  &__title{
    font-family: 'Playfair Display', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 43px;
    color: #000000;
    margin-bottom: 20px;
  }

  &__contact{
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 20px;

    *{
      font-size: inherit;
    }
  }
  .second-eamil{
    margin-top: 5px;
    padding-left: 57px;
  }
  &__contact-title{
    font-family: 'Roboto Condensed',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: #636A76;
  }

  &__contact-txt{
    margin: 0 0 20px;
    font-family: 'Roboto Condensed',sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #636A76;

    &-red{
      color: #B20C2A;
      font-weight: bold;
      font-size: 18px;
    }

    &:last-child{
      margin: 0;
    }
  }

  &__address-title{
    margin-bottom: 20px;
    font-family: 'Roboto Condensed',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #636A76;
  }
  .button-impressum{
    width: 226px;
    height: 60px;
    background: linear-gradient(100.85deg, #8F0017 10.13%, #E73149 103.62%);
    box-shadow: 10px 4px 25px rgba(0, 0, 0, 0.25), -10px 10px 25px rgba(173, 127, 135, 0.5);
    border-radius: 10px;
    font-family: 'Roboto Condensed',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    padding: 18px 23px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
  }
}
.disc__title{
  font-family: 'Roboto Condensed',sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #B20C2A;
}
.disc__txt{
  font-family: 'Roboto Condensed',sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #636A76;
}

.teamlead{
  text-align: center;

  &__title{
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 30px;
  }

  &__img-wrp{
    box-shadow: 0px 0px 20px #CED7DB;
    max-width: 286px;
    margin: 0 auto 30px;
  }

  &__img{
    display: block;
    border-radius: 6px;
  }

  &__name{
    font-family: 'Playfair Display',sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
  }

  &__contact{
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 140%;
    color: #636A76;
  }

  &__contact-title{
    font-weight: 700;
  }

  &__contact-link{
    font-weight: 500;
  }
}

.disclaimer{
  padding: 40px 0 70px;

  &__text{
    height: 560px;
    column-count: 2;
    column-gap: 60px;

    overflow: hidden;

    h3{
      font-size: 20px;
      line-height: 1.5;
      margin-bottom: 20px;
    }

    p{
      line-height: 1.71;
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .section-title{
    text-align: center;
  }
  &__text {
    max-width: 1255px;
    margin: 0 auto;
    h3{
      color: #B20C2A;
      font-family: 'Roboto Condensed',sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
    }
  }

}

@media screen and (min-width: 768px) and (max-width: 1199px){
  .impressum{
    &__wrapper{
      padding: 40px 40px 40px;
    }

    &__contact{
      font-size: 16px;
    }
  }

  .teamlead{
    margin-left: 15px;
  }

  .disclaimer__text{
    height: auto;
  }
}

@media screen and (max-width: 991px){
  .impressum{
    &__wrapper{
      padding: 30px 20px;
    }
  }

  .disclaimer__text{
    height: auto;
    column-count: 1;
    column-gap: 0;
  }
}

@media screen and (max-width: 767px){
  .impressum{

    padding: 40px 0;

    &__wrapper{
      padding: 25px 30px 40px;
      flex-direction: column;
    }

    &__title{
      font-size: 22px;
      margin-bottom: 18px;
    }

    &__contact{
      font-size: 16px;
      line-height: 1.15;
    }

    &__contact-txt{
      margin: 0 0 15px;
      &:last-child{
        margin: 0;
      }
    }

    &__address{
      margin-bottom: 20px;
    }

    &__address-title{
      margin-bottom: 10px;
    }
  }

  .disclaimer{
    padding: 50px 0 50px;

    h3{
      line-height: 1.4;
      margin-bottom: 15px;
    }

    p{
      line-height: 1.4;
      margin-bottom: 15px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 425px){
  .impressum{
    overflow: hidden;
    &__wrapper{
      margin: 0 -15px;
      padding: 25px 15px 40px;
    }
  }

  .teamlead{
    &__contact{
      display: flex;
      flex-direction: column;
    }
  }
}