.main-screen{
  @media all and  (max-width: @max-md) {
    text-align: center;
  }
  .component{
    &-title,
    &-subtitle{
      @media all and  (min-width: @min-md){
        text-align: left;
      }
    }
    &-title{
      font-family: @playfair;
      color: @bc;
      font-size: 60px;
      margin-bottom: 25px;
      @media all and  (max-width: @max-md){
        font-size: 45px;
      }
      @media all and  (max-width: @max-sm){
        line-height: 50px;
        margin-bottom: 10px;
      }
    }
    &-subtitle{
      font-size: 32px;
      font-weight: 400;
      line-height: 37px;
      width: 55%;
      @media all and  (max-width: 1280px){
        font-size: 28px;
      }
      @media all and  (max-width: 1080px){
        width: 40%;
        line-height: 30px;
      }
      @media all and  (max-width: @max-md){
        width: 100%;
        font-size: 18px;
      }
    }
  }

}
